import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import homeMap from '../views/homeMap/homeMap.vue'
import About from '../views/about.vue'
import Login from '../views/login/login.vue'
import LoginWechat from '../views/login/wechat.vue'
import LoginAlipay from '../views/login/alipay.vue'
import Confirm from '../views/confirm/confirm.vue'
import Wallet from '../views/wallet/wallet.vue'
import Result from '../views/result/result.vue'
import Order from '../views/order/order.vue'
import Setting from '../views/setting/setting.vue'
import Help from '../views/help/help.vue'
import WalletIn from '../views/wallet/wallet_in.vue'
import WalletOut from '../views/wallet/wallet_out.vue'
import WalletList from '../views/wallet/wallet_list.vue'
import GoogleMap from '../views/map/google-index.vue'
import ShopDetail from '../views/nearby/shop'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/loginborrow',
    name: 'loginborrow',
    component: Login,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/',
    name: 'homeMap',
    component: homeMap,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/login_wechat',
    name: 'login_wechat',
    component: LoginWechat,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/login_alipay',
    name: 'login_alipay',
    component: LoginAlipay,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: Confirm
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: Wallet
  },
  {
    path: '/result',
    name: 'result',
    component: Result
  },
  {
    path: '/order',
    name: 'order',
    component: Order
  },
  {
    path: '/setting',
    name: 'setting',
    component: Setting,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  },
  {
    path: '/wallet_in',
    name: 'wallet_in',
    component: WalletIn
  },
  {
    path: '/wallet_out',
    name: 'wallet_out',
    component: WalletOut
  },
  {
    path: '/wallet_list',
    name: 'wallet_list',
    component: WalletList
  },
  {
    path: '/google_map',
    name: 'google_map',
    component: GoogleMap,
    alias: '/map'
  },
  {
    path: '/nearby_shop',
    name: 'nearby_shop',
    component: ShopDetail
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('to', to)
  const token = store.state.token
  if (to.name === 'home' || to.name === 'homeMap') {
    if (to.query.qrcode) {
      store.commit('qrcode', to.query.qrcode)
      store.commit('zhcode', '')
    }
    if (to.query.zhcode) {
      store.commit('zhcode', to.query.zhcode)
      store.commit('qrcode', '')
    }
  }
  if (store.state.browser === 'wechat' && window.location.origin.includes('m.nooppower')) {
    console.log('window.location.href', window.location.href, window.location.href.indexOf('qrcode=') !== -1)
    if (store.state.qrcode) {
      window.location.replace(`https://nooppower.gzz8j.com/#/?qrcode=${store.state.qrcode}`)
    } else if (window.location.href.indexOf('qrcode=') !== -1) {
      let qrArr = window.location.href.split('qrcode=')
      let qr = qrArr[qrArr.length - 1]
      window.location.replace(`https://nooppower.gzz8j.com/#/?qrcode=${qr}`)
    } else if (window.location.origin.includes('m.nooppower.com')) {
      window.location.replace(`https://nooppower.gzz8j.com/#/`)
    }
    const currDomain = window.location.origin
    store.commit('domain', currDomain)
    return
  }
  if (to.query.AppleIdToken) {
    console.log('AppleIdToken', to.query.AppleIdToken)
    store.commit('appleIdToken', to.query.AppleIdToken)
  }
  if (to.matched.some(r => r.meta.ignoreAuth) || token) {
    next()
  } else {
    next({
      path: '/login'
    })
  }
})

export default router
