<template>
  <div id="app">
    <router-view />
    <popup-loading v-if="loading"></popup-loading>
  </div>
</template>

<script>
import { PopupLoading } from "./components/popup"
import { mapState } from "vuex"
import { vConsoleWidget } from "@/utils/index"
export default {
  components: {
    PopupLoading
  },
  computed: {
    ...mapState(["loading"])
  },
  created() {
    const { vc } = this.$route.query
    if (vc === "true") vConsoleWidget()
  }
}
</script>

<style lang="less">
@import "./assets/css/public.less";
body {
  background: #f9f9f9;
  padding: 0;
  margin: 0;
  height: 100vh;
  color: #333333;
}
#app {
  font-size: 28px;
}
.btn {
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #10d269;
  font-size: 32px;
  border-radius: 20px;
}
</style>
