<template>
  <!-- <page :title="i18n.title"> -->
  <div class="home">
    <div class="google-map">
      <google-map
        :locationList="list"
        :latLng="latLng"
        @center="onCenter"
        @zoom="onZoom"
        @setItem="onSetItem"
        @onmap="show = false"
      />
      <div class="home-left">
        <van-image
          :src="require('@/assets/imgs/icon_More@2x.png')"
          width="1rem"
          @click="isMenuView = true"
        ></van-image>
      </div>
      <div class="set-lang" @click="isLangView = true">
        <img class="set-lang-icon" :src="setLangIcon" alt="" />
        <span class="set-lang-label">{{ locale.label }}</span>
      </div>
      <!-- <div class="home-right">
      <van-image :src="require('@/assets/imgs/icon_messages@2x.png')" width="1rem" @click="onClick"></van-image>
    </div> -->
      <div class="home-message" v-if="userNoPayOrder.pId">
        <div class="home-m-box">
          <!-- <van-image
            :src="require('assets/imgs/powerIn.png')"
            width="1.3rem"
          ></van-image> -->
          <div class="m-box-title">
            {{ i18nHome.no_pay_order_tip }}
            {{ userNoPayOrder.pCurrencyName + userNoPayOrder.pJiesuan }}
          </div>
          <div class="m-box-method" @click="goOrdersPay(userNoPayOrder)">
            <span style="margin-right: 5px">{{ i18nHome.payment }}</span>
            <van-icon name="arrow"></van-icon>
          </div>
        </div>
      </div>
      <div class="home-bottom">
        <!-- <div class="home-scan flex-s">
        <div class="home-scan-aside" @click='onClick'>
           <img :src="require('@/assets/imgs/icon_search@2x.png')">
           <span>search</span>
        </div>
        <div class="home-scan-aside" @click="onClick">
           <img :src="require('@/assets/imgs/icon_help.png')">
           <span>help</span>
        </div>
      </div> -->
        <div class="scan-box">
          <div class="flex-s center-scanbtn" @click="scanQr">
            <van-image
              width="0.8rem"
              :src="require('@/assets/imgs/icon_Scan@2x.png')"
            ></van-image>
            <span> {{ i18nHome.scan }}</span>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-if="item && show" class="shop-info">
          <div class="shop-info-head">
            <div class="shop-title">
              <h3>{{ item.shopName }}</h3>
              <span
                :class="[
                  item.infoStatus === '在线' ? 'shop-tag-active' : '',
                  'shop-tag'
                ]"
                >{{ $t(`map.${item.infoStatus}`) }}</span
              >
            </div>
            <a @click="goDetail"
              ><img src="@/assets/imgs/popup_btn_navigation.png" alt=""
            /></a>
          </div>
          <div class="shop-info-content">
            <div class="shop-logo">
              <img v-if="item.shopBanner" :src="item.shopBanner" alt="" />
              <img v-else src="../../assets/imgs/banner-home.jpg" alt="" />
            </div>
            <div class="shop-detail">
              <div class="shop-time">
                <span class="time">
                  <img src="../../assets/imgs/map.png" alt="" />
                  {{ item.shopAddress1 }}
                </span>
                <span class="time">
                  <img src="../../assets/imgs/distance.png" alt="" />
                  {{ item.distance }}
                </span>
                <span class="time">
                  <img src="../../assets/imgs/icon_time.png" alt="" />
                  {{ item.shopTime }}
                </span>
              </div>
              <div class="shop-op">
                <span class="free-num"
                  >{{ i18n.free }}：{{ item.freeNum }}</span
                >
                <span class="return-num"
                  >{{ i18n.return }}：{{
                    Number(item.batteryNum) - Number(item.freeNum)
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <select-lang v-model="isLangView"></select-lang>
    <popup-warp
      v-model="isMenuView"
      dialogTransition="slide-left"
      style="background: #fff; height: 100vh"
    >
      <menu-side></menu-side>
    </popup-warp>
    <popup-warp
      v-model="ishomeSearch"
      position="bottom"
      class="search"
      style="border-radius: 60px 60px 0px 0px"
    >
      <div class="pop-box">
        <searchPlus v-model="searchText" @handleValue="reload"></searchPlus>
      </div>
    </popup-warp>
    <div class="fixed" v-if="isPickUpShow" @click="isPickUpShow = false">
      <ScanQr @scanQr="onScanQr" />
    </div>
  </div>
  <!-- </page> -->
</template>

<script>
import { mapState, mapGetters } from "vuex"
import GoogleMap from "@/views/map/google-map.vue"
import ScanQr from "@/views/scanQr/scanQr.vue"
import { debounce } from "../../utils/index"
import { getQueryString } from "../../utils/url.js"
import { SHOP_LIST, QR_CODE } from "../../apis/cabinet"
import { USER_INFO, ORDER_LIST } from "../../apis/user.js"
import { PopupWarp } from "@/components/popup"
import { MenuSide } from "@/components/menu"
import { SelectLang } from "@/components/select"
import searchPlus from "@/components/searchPlus/searchPlus.vue"
export default {
  components: {
    GoogleMap,
    PopupWarp,
    searchPlus,
    MenuSide,
    ScanQr,
    SelectLang
  },
  data() {
    return {
      latLng: [22.631123, 113.820622],
      zoomLevel: "4",
      list: [],
      item: null,
      show: false,
      isMenuView: false,
      ishomeSearch: false,
      isPickUpShow: false,
      isLangView: false,
      searchText: "",
      userNoPayOrder: {} // 用户未支付订单
    }
  },
  computed: {
    ...mapGetters(["locale"]),
    ...mapState(["user", "token", "browser", "qrcode", "domain"]),
    i18n() {
      return this.$t("map")
    },
    i18nHome() {
      return this.$t("home")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    setLangIcon() {
      return require(`../../assets/imgs/flags/img_${this.$i18n.locale}.png`)
    }
  },
  watch: {
    latLng() {
      this.lazyLoad(this)
    },
    user: {
      handler(val, oldVal) {
        if (val && this.$route.query.qrcode) {
          this.onScan(this.$route.query.qrcode)
        }
      },
      immediate: true
    }
  },
  methods: {
    onClick() {
      console.log("未开放")
      this.$toast(this.i18nMsg.notopen)
    },
    reload(val) {
      console.log("reload===", val)
    },
    scanQr() {
      if (this.user && this.token) {
        this.isPickUpShow = true
      } else {
        this.$router.push("/login")
      }
    },
    onScanQr(qr) {
      console.log("qr====", qr)
      this.isPickUpShow = false
      let arr = qr.split("=")
      this.onScan(arr[1])
    },
    onScan(qrcode) {
      this.$loading(true)
      this.$post(
        `${QR_CODE}?id=${qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
          if (resp.zujieNum && resp.zujieNum > 6) {
            this.$confirm({
              title: this.i18nHome.msg_fail_title,
              content: `${this.i18nHome.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.vieworder,
              cancelText: this.i18nMsg.cancel
            })
              .then(() => {
                this.$router.push("/order")
                this.$store.commit("qrcode", "")
              })
              .catch(() => {
                this.$store.commit("qrcode", "")
              })
          } else {
            this.$router.push("/home?cabinetshow=true")
            // this.isScan = true
            // this.isBtns = false
            // this.adVisible = true
          }
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    loginMpByCode(code) {
      this.$loading(true)
      this.$post(
        "loginByCode",
        { code },
        (resp) => {
          this.$loading(false)
          if (resp.token) {
            this.$store.commit("token", resp.token)
            this.loadUser()
          } else {
            this.$toast(this.i18n.msg_fail_login)
          }
        },
        (error) => {
          this.$loading(false)
          this.$toast(this.i18n.msg_fail_login)
          console.log(error)
        }
      )
    },
    loadUser() {
      this.$loading(true)
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          this.$loading(false)
          if (resp.user) {
            this.$store.commit("user", resp.user)
            this.$toast(this.i18n.msg_success_login)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user
            })
              .then(() => {
                this.loadUser()
              })
              .catch(() => {
                this.$store.commit("token", null)
              })
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: error
          })
            .then(() => {
              this.loadUser()
            })
            .catch(() => {
              this.$store.commit("token", null)
            })
          console.log(error)
        }
      )
    },
    getNearbyShop() {
      this.$loading(true)
      let formdata = new FormData()
      formdata.append("coordType", "WGS－84")
      formdata.append("mapType", "WGS－84")
      formdata.append("lat", this.latLng[0])
      formdata.append("lng", this.latLng[1])
      formdata.append("zoomLevel", this.zoomLevel)
      this.$post(
        SHOP_LIST,
        formdata,
        (resp) => {
          this.$loading(false)
          this.list = resp.list
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        },
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
    },
    onZoom(zoomLevel) {
      // console.log('zoom', zoomLevel)
      this.zoomLevel = zoomLevel
      this.lazyLoad(this)
    },
    lazyLoad: debounce((vm) => {
      vm.getNearbyShop()
    }, 500),
    onCenter(latLng) {
      this.latLng = latLng
      this.lazyLoad(this)
    },
    onSetItem(val) {
      this.item = val
      this.show = true
    },
    goDetail() {
      console.log("show detail")
      this.$router.push({ path: "nearby_shop", query: this.item })
    },
    // 获取用户订单
    getOrdersList() {
      const query = { page: 1, pageSize: 2 }
      this.$get(
        ORDER_LIST,
        query,
        (resp = {}) => {
          const records = (resp.page && resp.page.records) || []
          records.map((item) => {
            if (item.palipayjsSm === "代扣支付失败" && item.pZujie !== 2) {
              this.userNoPayOrder = item
            }
          })
        },
        () => {}
      )
    },
    // 点击未支付订单
    goOrdersPay(order = {}) {
      const query = {
        payType: "FK",
        price: order.pJiesuan,
        outTradeNo: order.pOrderid,
        cabinetID: order.pCabinetid
      }
      this.$router.push({
        path: "/confirm",
        query
      })
    }
  },
  created() {
    // if (this.browser === 'wechat') {
    //   console.log('window.location.href', window.location.href, window.location.href.indexOf('qrcode=') !== -1)
    //   if (this.qrcode) {
    //     window.location.replace(`https://nooppower.gzz8j.com/#/?qrcode=${this.qrcode}`)
    //   } else if (window.location.href.indexOf('qrcode=') !== -1) {
    //     let qrArr = window.location.href.split('qrcode=')
    //     let qr = qrArr[qrArr.length - 1]
    //     window.location.replace(`https://nooppower.gzz8j.com/#/?qrcode=${qr}`)
    //   } else if (window.location.origin.includes('m.nooppower.com')) {
    //     window.location.replace(`https://nooppower.gzz8j.com/#/`)
    //   }
    // } else if (window.location.origin.includes('nooppower.gzz8j.com')) {
    //   if (this.qrcode) {
    //     window.location.replace(`https://m.nooppower.com/#/?qrcode=${this.qrcode}`)
    //   } else if (window.location.href.indexOf('qrcode=') !== -1) {
    //     let qrArr = window.location.href.split('qrcode=')
    //     let qr = qrArr[qrArr.length - 1]
    //     window.location.replace(`https://m.nooppower.com/#/?qrcode=${qr}`)
    //   } else {
    //     window.location.replace(`https://m.nooppower.com/#/`)
    //   }
    // }
    const code = getQueryString("code")
    if (code && !this.user) {
      this.loginMpByCode(code)
    } else if (this.qrcode || this.$route.query.qrcode) {
      this.onScan(this.qrcode || this.$route.query.qrcode)
    }
    let latitude = this.$route.query.latitude
    let longitude = this.$route.query.longitude
    if (latitude && longitude) {
      this.latLng = [+latitude, +longitude]
    }
  },
  mounted() {
    // this.getNearbyShop()
    if (this.token && this.user) {
      setTimeout(() => this.getOrdersList(), 1600)
    }
  }
}
</script>

<style lang="less" scoped>
.search {
  /deep/.popup-warp {
    border-radius: 60px 60px 0px 0px;
  }
  .pop-box {
    // width: 100%;
    height: 1061px;
    background: #f5f5f5;
    box-shadow: 0px -10px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 60px 60px 0px 0px;
    padding: 50px 40px;
  }
}
.fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  // background: rgba(45, 45, 45, 0.5);
  height: 100%;
}
.home {
  position: relative;
  display: flex;
  .home-left {
    position: fixed;
    top: 96px;
    left: 40px;
    z-index: 1;
  }
  .home-right {
    position: fixed;
    top: 96px;
    right: 40px;
    z-index: 1;
  }

  .set-lang {
    position: fixed;
    top: 96px;
    right: 40px;
    z-index: 1;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 18px 28px;
    // border: 2px solid #ddd;
    background-color: #10d269;
    &-icon {
      width: 52px;
      margin-right: 8px;
    }
    &-label {
      color: #fff;
      font-size: 28px;
    }
  }
  .van-image /deep/ .van-image__img {
    width: 100%;
  }
  .home-message {
    width: 100%;
    position: fixed;
    top: 220px;
    margin: 0 auto;
    z-index: 1;
    .home-m-box {
      width: 80%;
      margin: 0 auto;
      height: 113px;
      background: #fff;
      border: 2px solid #b2e6c7;
      border-radius: 24px;
      display: flex;
      justify-content: start;
      position: relative;
      .m-box-title {
        max-width: 80%;
        padding: 18px 0 0 32px;
        font-size: 28px;
        font-family: Work Sans-Medium, Work Sans;
      }
      .m-box-method {
        position: absolute;
        bottom: 20px;
        right: 24px;
        font-size: 32px;
        color: #10d269;
      }
    }
  }
  .home-bottom {
    position: fixed;
    // bottom: 75px;
    bottom: 0;
    z-index: 1;
    width: 100%;
    .shop {
      float: right;
      margin-right: 40px;
      margin-top: -200px;
    }
    .home-scan {
      width: 570px;
      background: #000000;
      border-radius: 120px 120px 120px 120px;
      margin: 0 auto;
      display: flex;
      position: relative;
      padding: 10px 0;
      &-aside {
        width: 70px;
        z-index: 9;
        margin: 0 50px;
        color: #fff;
        text-align: center;
        img {
          width: 100%;
        }
      }
    }
    .scan-box {
      position: absolute;
      width: 100%;
      bottom: 40px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      .center-scanbtn {
        // width: max-content;
        // margin: 0 auto;
        border-radius: 100px;
        padding: 15px 48px;
        font-weight: bold;
        color: #10d269;
        font-size: 30px;
        background-color: #fff;
        > span {
          margin-left: 18px;
        }
      }
    }
  }
  .shop {
    justify-content: end;
  }
}
.google-map {
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  overflow: hidden;
}
.shop-info {
  position: fixed;
  bottom: 0;
  z-index: 100;
  // height: 350px;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background: #fff;
  padding-bottom: 30px;
  .shop-info-head {
    // width: 100%;
    display: flex;
    padding: 20px 20px 0 20px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    .shop-title {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      h3 {
        padding: 0;
        margin: 0;
      }
      .shop-tag {
        margin-left: 20px;
        font-size: 24px;
        padding: 5px 15px;
        border: 1px solid #cacdd1;
        border-radius: 10px;
      }
      .shop-tag-active {
        color: #8dd35f;
        border: 1px solid #8dd35f;
      }
    }
    img {
      width: 60px;
      height: 60px;
    }
  }
  .shop-info-content {
    display: flex;
    height: calc(100% - 49px);
    padding: 0px 20px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    .shop-logo {
      width: 200px;
      img {
        max-height: 180px;
        max-width: 200px;
      }
    }
    .shop-detail {
      height: 100%;
      margin-left: 14px;
      p {
        margin: 0;
        font-weight: 600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        word-break: break-all;
      }
      .shop-time {
        margin: 15px 0;
        font-size: 22px;
        line-height: 30px;
        .time {
          padding: 4px 0;
          position: relative;
          padding-left: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }
        }
        // .time::before {
        //   content: '';
        //   position: absolute;
        //   height: 25px;
        //   width: 2px;
        //   top: 10px;
        //   left: 0;
        //   background: #23a8f2;
        // }
      }
      .shop-op {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        .return-num {
          margin-left: 20px;
          padding: 5px 10px;
          border: 1px solid #23aaf2;
          border-radius: 10px;
          color: #23aaf2;
        }
        .free-num {
          padding: 5px 10px;
          border: 1px solid #8dd35f;
          border-radius: 10px;
          color: #8dd35f;
        }
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: height 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0;
}
.detailfade-enter-active,
.detailfade-leave-active {
  transition: opacity 0.5s;
}
.detailfade-enter, .detailfade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  overflow: hidden;
  .shop-content {
    height: 600px;
    width: 100%;
    position: absolute;
    z-index: 100;
    bottom: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0 -5px 10px #9e9b9b;
    background: #fff;
    .shop-title {
      display: flex;
      flex-direction: column;
      padding: 20px;
      background: #fff;
      border-radius: 20px;
      .title-info {
        padding: 0 30px;
        p {
          margin: 0 0 10px 0;
        }
        .return-num {
          margin-left: 20px;
          padding: 5px 10px;
          border: 1px solid #23aaf2;
          border-radius: 10px;
          color: #23aaf2;
        }
        .free-num {
          padding: 5px 10px;
          border: 1px solid #8dd35f;
          border-radius: 10px;
          color: #8dd35f;
        }
        .shop-time {
          h4 {
            margin: 20px 0;
            padding: 10px 0;
            border-bottom: 1px solid #cacdd1;
          }
        }
      }
      .shop-title-head {
        display: flex;
        flex-direction: row;
        align-items: center;
        h2 {
          margin: 10px 30px;
          padding: 20px 0;
          border-bottom: 1px solid #cacdd1;
        }
        .shop-tag {
          margin-left: 20px;
          font-size: 24px;
          padding: 5px 15px;
          border: 1px solid #cacdd1;
          border-radius: 10px;
        }
        .shop-tag-active {
          color: #8dd35f;
          border: 1px solid #8dd35f;
        }
      }
    }
    .address-detail {
      p {
        margin: 0;
        text-align: left;
        padding: 0 30px;
      }
    }
  }
}
</style>
<style lang="less">
.home {
  .slide-up {
    bottom: 10%;
  }
}
</style>
