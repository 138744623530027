<template>
  <page :title="i18n.wallet" :showBack="false" :isEmit="true" @onBack="onBack">
    <div @click="selectedItem($event)">
      <div
        :class="
          selected
            ? selected.pId === -10
              ? 'wallet-balance selected'
              : 'wallet-balance'
            : 'wallet-balance'
        "
        v-if="
          user.pbalance > 0 ? (selected ? selected.pId === -10 : true) : false
        "
      >
        <div class="wallet-balance-warp">
          <div class="wallet-balance-label">{{ i18n.balance }}</div>
          <div class="wallet-balance-value">
            {{ user && user.pcurrencyName }}{{ (user && user.pbalance) || 0 }}
          </div>
          <div class="wallet-balance-label">
            {{ i18n.deposit }}: {{ user.pyajin }}
          </div>
        </div>
        <div class="wallet-balance-more 1">
          <img src="../../assets/imgs/wallet_more.png" />
        </div>
        <div class="Mask" :data-item="JSON.stringify({ pId: -10 })"></div>
      </div>
      <template v-for="item in list">
        <div
          :class="
            selected
              ? item.pDailiId === selected.pDailiId &&
                item.pCurrencyType === selected.pCurrencyType
                ? 'wallet-balance selected'
                : 'wallet-balance'
              : 'wallet-balance'
          "
          v-if="
            selected
              ? item.pDailiId === selected.pDailiId &&
                item.pCurrencyType === selected.pCurrencyType
              : true
          "
          :key="item.pId"
        >
          <div class="wallet-balance-warp">
            <div class="wallet-balance-label">{{ i18n.balance }}</div>
            <div class="wallet-balance-value">
              {{ item.pCurrencySymbol }}{{ item.pBalance || 0.0 }}
            </div>
            <!-- <div class="wallet-balance-label">
              {{ i18n.deposit }}: {{ item.pDepositAmount || 0 }}
            </div> -->
          </div>
          <div class="wallet-balance-more">
            <img src="../../assets/imgs/wallet_more.png" />
          </div>
          <div class="Mask" :data-item="JSON.stringify(item)"></div>
        </div>
      </template>
    </div>
    <div class="wallet-btn" v-if="selected !== null">
      <div
        v-if="selected.pId !== -10"
        @click="
          goRouter(
            `/wallet_in${
              query && query.price
                ? `?${querypath}`
                : `?pbalance=${selected.pBalance}`
            }`
          )
        "
      >
        <img src="../../assets/imgs/wallet_pull.png" />
        <span>{{ i18n.wallet_in }}</span>
      </div>
      <div @click="goRouter(`/wallet_out`)">
        <img src="../../assets/imgs/wallet_push.png" />
        <span>{{ i18n.wallet_out }}</span>
      </div>
    </div>
  </page>
</template>

<script>
import { USER_INFO, USER_WALLET } from "../../apis/user.js"
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["user", "cabinet"]),
    i18n() {
      return this.$t("mine")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    // walletlist () {
    //   return this.list.filter(item => {
    //     return item.pBalance > 0
    //   })
    // },
    agentOpenId() {
      if (this.cabinet && this.cabinet.userWallet) {
        return this.cabinet.userWallet.pDailiId
      } else {
        return ""
      }
    }
  },
  data() {
    return {
      list: [],
      // list: [{
      //   pbalance: 1,
      //   pId: 1,
      //   pdepositAmount: 1,
      //   pcurrencySymbol: '$'
      // }, {
      //   pbalance: 2,
      //   pId: 2,
      //   pdepositAmount: 2,
      //   pcurrencySymbol: '$'
      // }],
      selected: null,
      query: null,
      querypath: window.location.href.split("?")[1]
    }
  },
  methods: {
    onBack() {
      this.$router.replace("/")
    },
    selectedItem(e) {
      console.log(JSON.parse(e.target.dataset.item))
      let selecteddata = JSON.parse(e.target.dataset.item)
      if (
        this.selected &&
        selecteddata.pDailiId === this.selected.pDailiId &&
        selecteddata.pCurrencyType === this.selected.pCurrencyType
      ) {
        this.selected = null
        this.$store.commit("wallet", null)
        return
      }
      this.$store.commit("wallet", selecteddata)
      this.selected = selecteddata
    },
    loadUser() {
      this.$loading(true)
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          this.$loading(false)
          if (resp.user) {
            this.$store.commit("user", resp.user)
            this.getWalletList()
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user,
              confirmText: this.i18nMsg.retry,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.loadUser()
            })
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.loadUser()
          })
          console.log(error)
        }
      )
    },
    goRouter(path) {
      this.$router.push(path)
    },
    getWalletList() {
      this.$loading(true)
      this.$get(
        USER_WALLET,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          this.list = resp.data
          if (this.list && this.list.length > 0) {
            this.selected = this.list[0]
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.getWalletList()
          })
          console.log(error)
        }
      )
    }
  },
  created() {
    this.query = this.$route.query
    this.loadUser()
    this.$store.commit("qrcode", "")
  }
}
</script>

<style lang="less">
.selected {
  border: solid 2px #309df7;
  border-top: solid 2px #309df7 !important;
}
.Mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.wallet-balance {
  position: relative;
  margin: 40px;
  // background: linear-gradient(90deg,#EBD460 0%,#E5BE53 100%);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 40px 60px 20px;
  color: #333333;
  display: flex;
  align-items: center;
  &-warp {
    flex: 1;
  }
  &-label {
    font-size: 32px;
  }
  &-value {
    font-size: 70px;
    font-weight: bold;
  }
  &-more {
    & img {
      width: 20px;
      height: 30px;
      display: block;
    }
  }
}
.wallet-btn {
  display: flex;
  padding: 20px 40px;
  & div {
    flex: 1;
    height: 72px;
    line-height: 72px;
    border-radius: 10px;
    text-align: center;
    border: 2px solid #333333;
    font-size: 28px;
    font-weight: 500;
    color: #333333;
    margin: 0 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & img {
    width: 40px;
    height: 40px;
    display: block;
  }
  & span {
    line-height: 40px;
    padding-left: 15px;
  }
}
</style>
