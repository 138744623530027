export default {
  home: {
    minute: 'menit',
    price_info: 'Gratis untuk {mianfei} menit pertama. Biaya harian maksimum sebesar {currencyName}{fengding}. Deposit akan secara otomatis dipotong ketika sewa mencapai {currencyName}{yajin}',
    feature_1: 'Disesuaikan dengan semua jenis ponsel',
    feature_2: 'Dapat dikembalikan ke situs lainnya',
    feature_3: 'Desain khusus - pengisian daya nirkabel (hanya untuk modul yang didukung)',
    tips_1: 'Deposit{currencyName}{yajin}Anda akan dikembalikan kepada Anda setelah Anda mengembalikan power bank.',
    tips_2: 'Deposit isi ulang {currencyName}{yajin} bisa disewa, habis bisa dikembalikan',
    tips_3: 'Saldo saat ini adalah{currencyName}{myyue}, yang dapat disewakan secara langsung ',
    rule_desc1: '1. Penggunaan powerbank gratis selama {mianfei} menit pertama.',
    rule_desc2: '2. Biaya sewa powerbank adalah {currencyName}{jifei}/{jifeiDanwei}.',
    rule_desc3: '3. Setelah melakukan pembayaran, dana sebesar {currencyName}{yajin} akan masuk ke deposit.',
    rule_desc4: '4. Durasi penggunaan powerbank akan terhitung setelah powerbank keluar dari mesin, dan biaya sewa akan dipotong dari deposit.',
    rule_desc5: '5. Setelah pengembalian powerbank, sisa deposit dapat ditarik kembali. Estimasi penarikan adalah 1-3 hari kerja.',
    rule_desc6: '6. Biaya sewa maksimum per hari dikenakan sebesar {currencyName}{fengding} per hari.',
    rule_desc: 'Aturan Pembayaran:',
    submit: 'Mulai sewa ',
    borrow: 'Sewa',
    msg_fail_title: 'Kesalahan',
    msg_fail_content: 'Penyewaan gagal, silakan coba lagi',
    msg_rentcount: 'Saat ini Anda memiliki pesanan {zujieNum} yang tidak ditutup',
    goborrow: 'Pergi untuk mengisi daya!!!',
    nearby: 'Lihat outlet terdekat',
    preauth: 'Pra-otorisasi',
    deposit_amount: 'Jumlah setoran',
    scan: 'Pindai kode QR ',
    msg_order_temp: 'Apakah anda ingin melakukan deposit dan mulai menyewa?saldo deposit dapat di tarik dari dompet anda setelah pengembalian powerbank.',
    msg_confirm: 'Konfirmasi',
    msg_cancel: 'Batal',
    payment: 'Payment',
    no_pay_order_tip: "Anda memiliki pesanan yang belum dibayar"
  },
  login: {
    code_title: 'Verifikasi SMS',
    phone_number: 'Nomor Telepon',
    verification_code: 'Masukkan kode verifikasi SMS',
    captcha_code: 'Kode',
    send_code: 'Kirim Kode',
    login: 'Login',
    agree: 'Dengan mengeklik Login, Anda setuju untuk',
    readme: 'Perjanjian Pengguna',
    msg_fail_login: 'Login gagal',
    msg_success_login: 'Login berhasil',
    msg_fail_user: 'Kesalahan mendapatkan informasi pengguna. Apakah Anda ingin mencoba lagi?',
    sign_in_with: 'Masuk dengan',
    no_sms: 'Tidak menerima pesan teks',
    retry: 'Kirim ulang'
  },
  confirm: {
    title: 'Konfirmasi Pembayaran',
    paynow: 'Pembayaran segera',
    SMSConfirm: 'Bayarlah sesuai dengan pesan teks yang diterima di ponsel Anda dan klik tombol konfirmasi untuk melanjutkan.',
    pay_label: 'Jumlah',
    auth_label: 'Pra-otorisasi ',
    price_tips: 'Deposit dapat ditarik kembali setelah pengembalian powerbank, Estimasi penarikan adalah 1-3 hari kerja.',
    auth_tips: 'Jumlah pra-otorisasi sebesar {0}{1} wajib diisi. Jumlah sewa yang sebenarnya terjadi akan dikurangi ketika powerbank dikembalikan.',
    pay_type: 'Pilih metode pembayaran yang Anda inginkan',
    submit: 'Konfirmasi pembayaran',
    msg_loading: 'Memuat...',
    msg_fail_title: 'Peringatan',
    msg_fail_payment: 'Gagal memulai pembayaran deposit',
    msg_fail_order: 'Gagal membuat pesanan sewa',
    msg_fail_support: 'Sistem tidak didukung',
    msg_fail_confirm: 'Konfirmasi',
    msg_fail_retry: 'Coba lagi.',
    msg_fail_cancel: 'Batal',
    noPhone: 'Silakan masukkan nomor telepon'
  },
  mine: {
    title: 'Profil Pengguna',
    wallet: 'Dompet Saya',
    balance: ' Saldo Saya ',
    deposit: 'Deposit',
    wallet_in: 'isi saldo',
    wallet_out: 'Tarik Saldo',
    wallet_log: 'Log Dompet',
    order: 'Pesan',
    coupon: 'Kupon',
    login_tips: 'Silakan Masuk',
    msg_fail_title: 'Kesalahan',
    msg_fail_update: 'Modifikasi gagal, silakan coba lagi',
    msg_loading: 'Memuat...',
    msg_fail_user: ' Kesalahan mendapatkan informasi pengguna. Apakah Anda ingin mencoba lagi? ',
    help: 'Bantuan',
    setting: 'Pengaturan',
    logout: 'Keluar',
    buy: 'Beli peralatan',
    home: 'Rumah',
    service_tel: "<b>Telepon layanan pelanggan dan kerjasama untuk bergabung</b> <br/>Wechat XRW870282133<br/>Whatsapp +62 82166666619"
  },
  msg: {
    no_phone: 'Diperlukan nomor telepon',
    loading: 'Memuat...',
    title: 'Petunjuk',
    success: ' Operasi yang berhasil ',
    error: ' Kesalahan tidak diketahui, silakan coba lagi nanti.',
    roleerror: ' Izin tidak mencukupi, silakan berkonsultasi dengan administrator ',
    neterror: ' Koneksi jaringan gagal. Silakan coba lagi nanti ',
    confirm: ' Konfirmasi ',
    download: ' Unduh APP ',
    retry: ' Coba lagi. ',
    cancel: ' Batal ',
    title_fail: 'Peringatan',
    vieworder: 'View order',
    success_payment: 'Payment completed',
    success_buy: 'Buy completed',
    fail_payment: 'Payment failure',
    keyword: 'keyword',
    ispayorder: 'Please confirm if payment has been made?',
    unpay: 'Unpaid',
    yespay: 'Paid',
    notopen: 'Not yet open',
    appoffline: 'The app has not been launched yet, please wait'
  },
  result: {
    title: 'Rental confirmed',
    tips: 'Your Powerbank is ready, grab it and Enjoy your day, stress free!',
    gotit: 'Got It',
    price_plan: 'Charge Standard',
    msg_success: 'Successfully Rented',
    msg_tips_loading: 'Portable battery is popping up, please wait patiently...',
    msg_result_loading: 'The payment result is being confirmed...',
    msg_fail_confirm: 'Konfirmasi',
    btn_usage: 'Usage note',
    btn_return: 'Return note',
    deposit: 'Deposit',
    msg_fail_content: 'Reqest failed, please try again',
    price_label: 'per hour',
    price_info: 'Free of charge for the first {mianfei} mins，A maximum dialy charge of {ccurrency}{fengding}，Deposit will be automatically deducted when rent reaches {ccurrency}{yajin}',
    tips_1: 'Please collect power bank <span class="number">No.{number}</span> as the picture shown',
    tips_2: 'Please tidy the cables and insert the power bank to bottom with the <span class="highlight">[arrow downward]</span> when return',
    msg_note_usage: 'The power bank is equipped with 3 types of cables and wireless changing,please use as needed.',
    msg_note_return: 'Please tidy this cables and insert the power bank to the bootom with the [arrow downward] when return.',
    return_info: 'When return,set the cables well and insert the side with metal chips into an empty socket till it reaches the bottom and stabilized.'
  },
  result_msg: {
    loading: 'Memuat...',
    refresh: 'Refresh'
  },
  order: {
    title: 'Daftar penyewaan',
    in_use: 'In use',
    ended: 'Ended',
    msg_error: 'Request failed'
  },
  order_item: {
    order_number: 'No.',
    venue_rented: 'Tempat penyewaan',
    time_rented: 'Durasi sewa',
    venue_returned: 'Venue returned',
    time_returned: 'Time returned',
    charge_standard: 'Charge standard',
    charge_standard_info: '{pJifei} {pCurrency} per hour(first {pMian} mins for free)',
    rent_occurred: 'Rent occurred',
    returned: 'Dikembalikan',
    unreturned: 'Unreturned',
    type_0: 'Not Completed',
    type_1: 'Menyewa',
    type_2: 'Canceled',
    type_3: 'Dikembalikan',
    type_4: 'Fault occurred',
    type_5: 'Overtime',
    min: 'Min',
    payment: 'Payment'
  },
  setting: {
    title: 'Setting',
    lang: 'Language'
  },
  help: {
    title: 'FAQ'
  },
  wallet_in: {
    title: 'Isi Ulang Saldo',
    price_label: 'Recharge amount',
    price_placeholder: 'Silakan masukkan jumlah isi ulang',
    balance: 'Saldo saat ini',
    price_auto: 'Pilih Jumlah Pembayaran',
    price_tips: 'Deposit dapat ditarik kembali setelah pengembalian powerbank, Estimasi penarikan adalah 1-3 hari kerja.',
    submit: 'Kirim',
    loading: 'Memuat...',
    paying: 'Lompat ke halaman pembayaran...',
    msg_title: 'Kiat',
    msg_fail_order: 'Gagal membuat pesanan isi ulang',
    msg_fail_payment: 'Gagal memulai pembayaran isi ulang',
    msg_fail_retry: 'Mencoba kembali'
  },
  wallet_out: {
    title: 'Withdrawal',
    price_label: 'Withdrawal amount',
    price_placeholder: 'Please enter the withdrawal amount',
    balance: 'Current balance',
    balance_all: 'Withdraw all',
    deposit: 'Deposit',
    deposit_tips: 'There is an daftar penyewaan. The deposit of Ksh{pyajin} is non-refundable',
    submit: 'Submit',
    apply: 'Apply',
    confirm: 'Konfirmasi',
    loading: 'Memuat...',
    msg_fail_price: 'The withdrawal amount is incorrect',
    msg_fail_balance: 'The withdrawal amount cannot be greater than the current balance',
    msg_fail_submit: 'Please fill in the complete information',
    msg_title: 'Kesalahan',
    msg_success_content: 'Apply Refund successful',
    confirmtitle: 'Tips',
    confirmcontent: 'The current wallet does not support cash withdrawal. Please note your contact information and refund manually',
    withdrawal_commission: 'Penarikan memerlukan biaya penanganan 5%. Apakah Anda ingin mengonfirmasi penarikan?',
    payee_name: 'Payee Name',
    payee_bankcard: 'Bank card number',
    payee_phone: 'Phone',
    cancelText: 'cancel',
    confirmText: 'Reapply'
  },
  map: {
    title: 'Near the equipment',
    result_msg: 'No equipment was found nearby',
    msg_title: 'Kesalahan',
    no_support: 'sorry! Your browser does not support location',
    my_position: 'my location',
    distance: 'distance:',
    shop_time: 'hours',
    free: 'loanable',
    return: 'returnable',
    shop_title: 'Merchant details',
    address: 'address',
    go_here: 'Go here',
    'on line ': 'on line',
    'offline ': 'offline'
  },
  scanQr: {
    NotAllowedError: 'Necesitas otorgar acceso a la cámara',
    NotFoundError: 'No hay cámara en este dispositivo',
    NotSupportedError: 'Contexto de seguridad requerido (HTTPS, localhost)',
    NotReadableError: 'La cámara está ocupada.',
    OverconstrainedError: 'La instalación de la cámara es inapropiada.',
    StreamApiNotSupportedError: 'Este navegador no admite API de transmisión'
  }
}
