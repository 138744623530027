<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { LOGIN_APPLE } from '../../apis/user'
export default {
  computed: {
    ...mapState(['appleIdToken', 'agentOpenId'])
  },
  data() {
    return {
      disabled: true
    }
  },
  methods: {
    loginByApple(token) {
      this.$loading(true)
      this.$post(
        LOGIN_APPLE,
        {
          token: token
        },
        resp => {
          this.$loading(false)
          if (resp.token) {
            this.$store.commit('token', resp.token)
            this.$store.commit('loginMode', 'Apple')
            this.$emit('success')
          } else {
            this.$toast('登录失败')
          }
        },
        error => {
          console.log(error)
          this.$loading(false)
          this.$toast('登录失败')
        }
      )
    },
    initApple() {
      // #/loginborrow
      // vm.$store.commit('loginMode', 'Apple')
      const vm = this
      setTimeout(() => {
        console.log('load appleapi', window.AppleID)
        if (window.AppleID) {
          window.AppleID.auth.init({
            clientId: 'com.nooppower.charge.web',
            scope: 'name email',
            redirectURI: `https://m.nooppower.com/cdb-app-api/v1/app/saas/loginAppleByCallback`,
            state: 'nooppower'
          })
          const buttonElement = document.getElementById(
            'sign-in-with-apple-button'
          )
          buttonElement.addEventListener('click', async () => {
            const data = await window.AppleID.auth.signIn()
            console.log('appledata===', data)
            if (data && data.authorization) {
              vm.loginByApple(data)
            }
          })
          vm.disabled = false
        } else {
          this.initApple()
        }
        // Listen for authorization success.
        window.document.addEventListener('AppleIDSignInOnSuccess', event => {
          // Handle successful response.
          console.log(event.detail.data)
          vm.loginByApple(event.detail.data)
        })

        // Listen for authorization failures.
        window.document.addEventListener('AppleIDSignInOnFailure', event => {
          // Handle error.
          console.log(event.detail.error)
        })
      }, 300)
      this.loadApple(document, 'script', 'apple-jsplatform')
    },
    loadApple(d, s, id) {
      let js = ''
      let fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        // this.initApple()
        // console.log('fafsfsdfsdfsd')
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src =
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
      fjs.parentNode.insertBefore(js, fjs)
    }
  },
  mounted() {
    if (this.appleIdToken) {
      this.loginByApple(this.appleIdToken)
    } else {
      this.initApple()
    }
    // this.loadApple(document, 'script', 'apple-jsplatform')
  }
}
</script>
