<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import { LOGIN_GOOGLE } from "../../apis/user"
let googleAuth2 = null
export default {
  data() {
    return {
      disabled: true
    }
  },
  methods: {
    onGoogle(googleUser) {
      if (this.disabled) {
        this.$toast("Loading...")
        return
      }
      this.$loading(true)
      googleAuth2
        .grantOfflineAccess()
        .then((authResult) => {
          console.log("authResult", authResult)
          if (authResult["code"]) {
            this.loginByGoogle(authResult["code"])
          } else {
            // There was an error.
            this.$loading(false)
            this.$toast("登录失败")
          }
        })
        .catch((error) => {
          console.log(error)
          this.$loading(false)
          this.$toast("登录失败")
        })
    },
    loginByGoogle(params) {
      this.$post(
        LOGIN_GOOGLE,
        {
          androidToken: params
        },
        (resp) => {
          this.$loading(false)
          if (resp.data && resp.data.token) {
            this.$store.commit("token", resp.data.token)
            this.$emit("success")
          } else {
            this.$toast("登录失败")
          }
        },
        (error) => {
          console.log(error)
          this.$loading(false)
          this.$toast("登录失败")
        }
      )
    },
    CredentialResponse(response) {
      console.log("JWT response: ", response)
      // console.log('Encoded JWT ID token: ', response.credential)
      // console.log('Decoded JWT ID token: ', decodeURIComponent(response.credential))
      let credential = response.credential
      this.loginByGoogle(credential)
    },
    initGoogle() {
      console.log("google2.initgoogle")
      // console.log(window.google)
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_APPID,
        callback: this.CredentialResponse
      })
      google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
        type: "icon",
        theme: "outline",
        size: "large",
        shape: "pill"
      })
      // google.accounts.id.prompt() // google 登录提示框
    },
    loadGoogle(d, s, id) {
      let js = ""
      let fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = "https://accounts.google.com/gsi/client"
      js.onload = () => {
        console.log("loadsuccess")
        this.initGoogle()
      }
      fjs.parentNode.insertBefore(js, fjs)
    }
  },
  mounted() {
    if (document.getElementById("google-jsplatform")) {
      this.initGoogle()
    } else {
      this.loadGoogle(document, "script", "google-jsplatform")
    }
  }
}
</script>
